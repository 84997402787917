import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'

import ChiSiamoImg from '../images/chi_siamo.jpg'

const pagina = {
  titolo: 'Chi siamo',
  imgUrl: ChiSiamoImg,
  paragrafi: [
    {
      id: 1,
      testo:
        'La Società MEDITERRANEA EXPRESS S.R.L., in sigla MED.EX. S.R.L. ha avviato la propria attività di impresa nel settore dei corrieri espressi dal 1° aprile 2014 con il marchio GLS.',
    },
    {
      id: 2,
      testo:
        'La attività di impresa è svolta nelle sedi operative (Sedi GLS) di Catania, Messina, Milazzo, Ragusa e Siracusa ed è organizzata per soddisfare le esigenze di spedizione espressa, di imprese e privati consumatori.',
    },
    {
      id: 3,
      testo:
        'In Sicilia Nord Orientale, la Società supporta l’attività e la crescita di migliaia di imprese che affidano le loro spedizioni al corriere espresso GLS, garantendo professionalità e tempi di consegna entro le 24/48 ore, in tutto il territorio nazionale (isole minori escluse) ed entro le 48/96 ore in tutta Europa e Svizzera.',
    },
    {
      id: 4,
      testo:
        'Il corriere espresso GLS nell’organizzazione dei propri processi produttivi, persegue obiettivi di  sostenibilità ambientale ed ha ottenuto la Certificazione ISO 14001.',
    },
  ],
}

const ChiSiamo = () => {
  return (
    <Layout>
      <SEO
        title="Chi siamo"
        description="Mediterranea Express - Corriere espresso GLS Sicilia"
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {pagina.titolo}
        </h1>
      </section>
      <section className="py-12 px-12 bg-white lg:rounded-lg shadow-xl">
        <div className="flex flex-row flex-wrap items-start justify-between">
          <div className="pr-12 w-full lg:w-2/3">
            {pagina.paragrafi.map((p) => {
              return (
                <div key={p.id} className="mb-6">
                  {p.testo}
                </div>
              )
            })}
          </div>
          <div className="w-full lg:w-1/3">
            <img
              src={pagina.imgUrl}
              alt={pagina.titolo}
              className="w-full rounded-lg"
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ChiSiamo
